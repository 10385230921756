#my-navbar-brand {
  color: var(--a-text);
  font-size: 1.5rem;
}

.disabled-nav-link {
  color: var(--neutralSecondary);
}

@media (prefers-color-scheme: dark) {
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%2812, 173, 133, 0.75%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
  }

  .navbar-toggler {
    color: var(--themePrimary)
  }
}