li>span {
  background-color: var(--btn-primary-bg-color) !important;
  border-top: 0;
  border-bottom: 0;
  border-color: var(--primary-background) !important;
}

li>a {
  background-color: #fff !important;
  border-top: 0;
  border-bottom: 0;
  color:#212529 !important;
  border-color: var(--primary-background) !important;
}
/* li a:active, li a:focus, li a:hover {
  background-color: #fff !important;
  border-top: 0;
  border-bottom: 0;
  color:#212529 !important;
  border-color: var(--primary-background) !important;
} */