/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */

/* WEBSITE COLORS: CLOSE TO NATURE */
:root {
  --light-grey: #F7F9FB;
  --green-earth: #687864;
  --green-earth-border: #0f100e;
  --green-earth-highlight: #455042;
  --green-earth-highlight-border: #374135;
  --deep-water: #31708E;
  --deep-water-border: #2b627c;
  --deep-water-highlight: #193a49;
  --deep-water-highlight-border: #122833;
  --troubled-water: #5085A5;
  --troubled-water-border: #3e7ea5;
  --shallow-water: #8FC1E3;
  --red-dirt: #E27D60;
  --red-dirt-border: #d8785d;
  --red-dirt-highlight: #c26950;
  --red-dirt-highlight-border: #a35844;

  /* These are the colors generated from the Microsoft theme builder tool https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/ */
  /* Primary color = #0cad85
Text color = #323232
Background color = #fafafa */

  --themePrimary: #0cad85;
  --themeLighterAlt: #f2fcf9;
  --themeLighter: #cef2e9;
  --themeLight: #a6e7d6;
  --themeTertiary: #5bceb1;
  --themeSecondary: #21b792;
  --themeDarkAlt: #0b9c78;
  --themeDark: #098465;
  --themeDarker: #07614b;
  --neutralLighterAlt: #f3f3f3;
  --neutralLighter: #efefef;
  --neutralLight: #e5e5e5;
  --neutralQuaternaryAlt: #d6d6d6;
  --neutralQuaternary: #cccccc;
  --neutralTertiaryAlt: #c4c4c4;
  --neutralTertiary: #c2c2c2;
  --neutralSecondary: #858585;
  --neutralPrimaryAlt: #4b4b4b;
  --neutralPrimary: #323232;
  --neutralDark: #272727;
  --black: #1d1d1d;
  --white: #fafafa;
}


@media (prefers-color-scheme: dark) {
  :root {
    --primary-text: var(--white);
    --primary-background: var(--neutralDark);
    --secondary-background: var(--neutralDark);
    --nutrition-facts-table-outline: var(--white);
    --btn-primary-bg-color: var(--themePrimary);
    --btn-primary-bg-color-hover: var(--themeDark);
    --btn-outline-primary-border: var(--white);
    --btn-outline-primary-bg-color: var(--neutralDark);
    --btn-outline-primary-bg-color-hover: var(--neutralPrimaryAlt);
    --a-text: var(--themeTertiary);
    --a-text-hover: var(--themeSecondary);
  }

  .card {
    border-color: var(--neutralLight);
    background-color: var(--primary-background);
  }

  #my-nav-link-disabled {
    color: var(--neutralSecondary);
  }

  #my-burger-menu {
    color: rgba(255, 255, 255, .55);
    border-color: rgba(255, 255, 255, .1);
    background-color: var(--white);
    height: 44px;
  }

  .btn-primary:disabled {
    background-color: var(--neutralPrimaryAlt);
    color: var(--neutralQuaternary);
  }

  .navbar-light .navbar-nav .nav-link.disabled {
    color: var(--neutralPrimaryAlt);
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --primary-text: var(--neutralPrimary);
    --primary-background: var(--white);
    --secondary-background: white;
    --nutrition-facts-table-outline: var(--black);
    --btn-primary-text: var(--white);
    --btn-primary-bg-color: var(--themePrimary);
    --btn-primary-bg-color-hover: var(--themeDark);
    --btn-outline-primary-border: var(--neutralPrimary);
    --btn-outline-primary-bg-color: var(--white);
    --btn-outline-primary-bg-color-hover: var(--neutralLighter);
    --a-text: var(--themeDark);
    --a-text-hover: var(--themeDarker);
  }

  .btn-primary:disabled {
    background-color: var(--neutralLighter);
    color: var(--neutralQuaternary);
  }

  #my-nav-link-disabled {
    color: var(--neutralSecondary);
  }
}

.my-card {
  margin-top: 40px;
}

#search-bar {
  border: solid 1px var(--btn-outline-primary-border);
}

#cart-recipe-item {
  border: solid 1px var(--btn-outline-primary-border);
}

#pl-navbar-brand {
  color: var(--themePrimary);
}

.active .page-link {
  background-color: var(--btn-primary-bg-color);
}

#userButton {
  color: var(--primary-text);
}

#userButton:hover {
  color: var(--a-text-hover);
}

#react-autowhatever-1 {
  color: var(--neutralPrimary);
  top: 35px;
  width: 100%;
  border: 1px solid #ced4da;
  line-height: 1.9;
}

.input-field-style {
  color: var(--primary-text);
  border-color: var(--btn-outline-primary-border);
  background-color: var(--btn-outline-primary-bg-color);
}

.input-field-style:hover,
.input-field-style:active,
.input-field-style:focus {
  color: var(--primary-text);
  border-color: var(--btn-outline-primary-border);
  background-color: var(--btn-outline-primary-bg-color);
}

.navbar-light .navbar-nav .nav-link {
  color: var(--primary-text);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:active {
  color: var(--a-text-hover);
}

#my-navbar-brand:hover #my-navbar-brand:active {
  color: var(--a-text-hover);
}

.step-number {
  border-right: var(--a-text);
  color: var(--a-text);
  font-weight: 600;
  border-top: none;
  border-style: solid;
  border-right-width: 3px;
  margin-bottom: 12px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  max-width: 40px;
}

a {
  color: var(--a-text);
  text-decoration: none;
}

a:hover,
a:active {
  color: var(--a-text-hover);
}

.btn-primary {
  color: var(--btn-primary-text);
  background-color: var(--btn-primary-bg-color);
  border-width: 0;
}

.btn:first-child:active,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  color: var(--btn-primary-text);
  background-color: var(--btn-primary-bg-color-hover);
}

.btn-outline-primary {
  color: var(--primary-text);
  border-color: var(--btn-outline-primary-border);
  background-color: var(--btn-outline-primary-bg-color);
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  color: var(--primary-text);
  border-color: var(--btn-outline-primary-border);
  background-color: var(--btn-outline-primary-bg-color-hover);
}

.btn-success {
  color: var(--white);
  background-color: var(--btn-primary-bg-color);
  border: 0;
}

.btn-success:hover,
.btn-success:active {
  color: var(--white);
  background-color: var(--btn-primary-bg-color-hover);
}

.btn-danger {
  color: var(--white);
  background-color: var(--red-dirt);
  border: 0;
}

.btn-danger:hover,
.btn-danger:active {
  color: var(--white);
  background-color: var(--red-dirt-highlight);
}

.todays-tens-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.todays-tens-symbols {
  height: 35px;
}

.todays-tens-symbols.absent {
  height: 20px;
  filter: grayscale(100%);
}

.recipe-edit-buttons {
  min-width: 132px;
  width: 100%;
}

.nbi-table {
  margin-top: 10px;
  border: 1px solid var(--nutrition-facts-table-outline);
  /* min-width: 358px; */
}

.nbi-table-entry {
  margin: 0 8px 8px 8px;
  border-bottom: 1px solid var(--nutrition-facts-table-outline);
}

.nbi-table-source {
  padding: 2px 0 10px 16px;
  font-size: small;
}

.nbi-table-entry:last-child {
  border: 0;
}

.border-top-1 {
  border-top: 1px solid #e5e5e5;
  padding-top: 10px;
}

.border-0 {
  border: 0;
}

.table {
  color: var(--primary-text) !important;
}

.navbar {
  background-color: var(--primary-background);
  margin-bottom: 0px !important;
}

.recipe-name-input {
  height: 2.5rem;
  margin-bottom: 20px;
}

.recipe-name {
  line-height: 2.5rem;
  font-size: 2.5rem;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.tooltip-style {
  font-weight: 600;
}

hr {
  margin: 8px 0;
}

.tag-style {
  color: var(--primary-text);
  font-variant: small-caps;
}

.tag-style:hover,
.tag-style:active {
  color: var(--primary-text);
  font-variant: small-caps;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--light-grey);
  background-color: var(--deep-water-highlight);
  border-color: var(--deep-water-highlight-border);
}

.troubled-water-color {
  background-color: var(--troubled-water);
  border-color: var(--troubled-water-border);
}

.deep-water-color {
  color: var(--deep-water);
}

.themePrimary-color {
  color: var(--themePrimary);
}

.red-dirt-color {
  color: var(--red-dirt);
}

.height-38 {
  height: 38px;
}

.height-32 {
  height: 32px !important;
}

.font-weight-600 {
  font-weight: 600;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.image-selector {
  width: 100%;
  margin-bottom: 0px;
}

.ingredient-col-left {
  padding-right: 5px;
}

.ingredient-col-middle {
  padding-left: 5px;
  padding-right: 5px;
}

.ingredient-col-right {
  padding-left: 5px;
}

.component-card {
  padding: 10px;
  background-color: var(--secondary-background);
}

.recipe-card {
  background-color: var(--secondary-background);
}

.review-card {
  padding: 16px 16px 0 16px;
}

.recipe-edit-row {
  margin-bottom: 10px;
}

.recipe-field-title {
  font-weight: 700;
  display: block;
  font-variant: small-caps;
}

.recipe-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 10px;
}

.badge {
  color: var(--themePrimary);
  font-variant: small-caps;
}

.cr-image-parent {
  height: 180px;
}

.card-recipe-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.favorite-button {
  position: relative;
  top: -180px;
  height: 60px;
  width: 60px;
  padding-left: 10px;
  padding-bottom: 4px;
}

/* RULE OF THUMB IS TO PLAN TO DESIGN FOR 320PX AS THE SMALLEST SCREEN A USER WOULD HAVE*/
.nft-row {
  width: 320px;
}

@media all and (min-width: 768px) {
  .performance-facts {
    width: 280px;
  }
}

/* From experimentation, below 767px the normal 180px looks bad*/
@media all and (max-width: 767px) {
  .nft-row {
    width: 100%;
  }

  .cr-image-parent {
    height: 140px;
  }

  .card-recipe-image {
    width: 100%;
    height: 140px;
    object-fit: cover;
  }

  .favorite-button {
    position: relative;
    top: -140px;
    height: 60px;
    width: 60px;
    padding-left: 10px;
    padding-bottom: 4px;
  }

  .get-smaller {
    padding-right: 20px;
  }

  .recipe-edit-buttons {
    margin-bottom: 10px;
  }
}

.list-image {
  height: 40px;
  width: 40px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 10%;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.border-top {
  border-top: 1px solid #e5e5e5;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

button.accept-policy {
  font-size: 1rem;
  line-height: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
  background-color: var(--primary-background);
  color: var(--primary-text);
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  line-height: 60px;
  /* Vertically center the text there */
}

.cart-header {
  font-weight: 700;
  font-variant: small-caps;
}

.count {
  text-align: center;
  width: 50px;
  display: inline;
  border-radius: 0;
  border: 0;
}

.plus-counter-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.minus-counter-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.component-on-right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.component-on-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.actual-display-inline {
  display: inline-block;
}

.do-not-overflow-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.form-control {
  border: 0;
}



.pl-button {
  margin-top: 30px;
  display: block;
  min-width: 200px;
}

#padding-0 {
  padding: 0;
}

.padding-8 {
  padding: 8px;
}

.padding-16 {
  padding: 16px;
}

.padding-top-2 {
  padding-top: 2px;
}

.padding-left-0 {
  padding-left: 0px;
}

.padding-left-4 {
  padding-left: 4px;
}

.padding-left-12 {
  padding-left: 12px;
}

.padding-left-58 {
  padding-left: 58px;
}

.padding-right-0 {
  padding-right: 0px;
}

.padding-right-10 {
  padding-right: 10px;
}

.margin-10 {
  margin: 10px;
}

.margin-bottom-0 {
  margin-bottom: 0px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-7 {
  margin-top: 7px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-left-4 {
  margin-left: 4px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.width-100 {
  width: 100%;
}

.width-320 {
  width: 320px;
}

.rating-and-author {
  margin: 10px;
}

.cart-ingredients-list {
  padding: 0px;
  margin-top: 8px;
}

.recipe-counter-column {
  width: 130px;
  height: 38px;
}

.new-curr-badge {
  position: absolute;
  left: 8px;
  top: 8px;
  width: 45px;
  border: solid 1px;
  background-color: transparent;
}

.new-curr-input {
  padding-left: 59px;
}

.ingredient-input {
  position: relative;
}

.ingredient-list {
  padding-left: 0px;
  margin-bottom: 8px;
  width: 100%;
}

.step-list {
  padding-left: 0px;
  width: 100%;
}

.ingredient-item {
  margin-bottom: 6px;
}

.cart-ingredient-item {
  margin-bottom: 6px;
  padding: 0;
}

.serving-counter {
  display: flex;
  justify-content: space-between;
}

.serving-counter-in-cart {
  display: flex;
  justify-content: space-between;
}

/* From Bootstrap container, 576px happens to be our breakpoint where we can't have 2 column in the same row for Directions label and Steps text */
@media all and (max-width: 576px) {
  .step-list {
    margin-bottom: 8px;
    width: 100%;
  }

  .ingredient-list {
    width: 100%;
    margin-bottom: 0;
  }

  .serving-counter {
    margin-top: 8px;
  }

  .detail-header {
    margin-top: 8px;
  }

  .recipe-field-title {
    width: 100%;
  }

  .component-name-field {
    padding-left: 4px;
  }
}

/* 
EXAMPLE FOR EXCEPTION ON PHONE LANDSCAPE
@media all and (max-width: 600px) and (orientation:landscape) 
{
} 
EXAMPLE WITH ALL DIFFERENT SCREEN SIZE BREAKPOINTS
@media all and (min-width:321px) and (max-width: 480px) {
}
@media all and (min-width:0px) and (max-width: 320px) {
}
*/

/* REACT AUTOSUGGEST SHIT */
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

/* NUTRITION FACTS TABLE CSS */
.nf-body {
  font-size: small;
  line-height: 1.4;
  box-sizing: content-box;
  margin-top: 10px;
}

.nf-p {
  margin: 0;
}

.performance-facts {
  border: 1px solid var(--nutrition-facts-table-outline);
  /* width: 280px; */
  padding: 0.5rem;
}

.performance-facts__table {
  border-collapse: collapse;
}

.performance-facts__title {
  font-weight: bold;
  font-size: 2rem;
  margin: 0 0 0.25rem 0;
}

.performance-facts__header {
  border-bottom: 10px solid var(--nutrition-facts-table-outline);
  padding: 0 0 0.25rem 0;
  margin: 0 0 0.5rem 0;
}

.performance-facts__header__p {
  margin: 0;
}

.performance-facts__table {
  width: 100%;
}

.performance-facts__table thead tr th,
td {
  border: 0;
}

.performance-facts__table th,
.performance-facts__table td {
  font-weight: normal;
  text-align: left;
  padding: 0.25rem 0;
  border-top: 1px solid var(--nutrition-facts-table-outline);
  white-space: nowrap;
}

.performance-facts__table td:last-child {
  text-align: right;
}

.performance-facts__table .blank-cell {
  width: 1rem;
  border-top: 0;
}

.thick-row {
  border-top: 5px solid var(--nutrition-facts-table-outline);
}

.small-info {
  font-size: 0.7rem;
}

.performance-facts__table__small {
  border-bottom: 1px solid var(--nutrition-facts-table-outline);
  margin: 0 0 0.5rem 0;
}

.nf-small-th-and-td {
  border: 0;
  padding: 0;
}

.nf-thead-tr {
  border-bottom: 1px solid var(--nutrition-facts-table-outline);
}

.nf-small-td-lastchild {
  text-align: left;
}

.performance-facts__table__grid {
  margin: 0 0 0.5rem 0;
}

.nf-td-lastchild::before {
  text-align: left !important;
  content: "•";
  font-weight: bold;
  margin: 0 0.25rem 0 0;
}

.nf-text-center {
  text-align: center;
}

.thick-end {
  border-bottom: 10px solid var(--nutrition-facts-table-outline);
}

.thin-end {
  border-bottom: 1px solid var(--nutrition-facts-table-outline);
}

.calories-title {
  font-size: 1.7rem;
}

.calories-qty {
  text-align: right;
  font-size: 2.4rem;
  line-height: 2.4rem;
  vertical-align: bottom;
}